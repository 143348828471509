import http from "../../system/Communicator";
import { AxiosError, AxiosResponse } from 'axios';
import { IUser, IValidatedCredentials, ILoginCredentials, IPasswordChange, IPermission, IPermissionChange, createDefaultUser, IPasswordOverwrite } from "./Users.model"
import ICrudService from "../../system/ICrudService"
import { IPagedResponse, PageQuery } from "../../system/Pagination.model";
import { Sorting } from "../../system/CRUDTable";
import { combineQueries } from "../../system/RequestResponse.model";
import { IRole, IScopedRole, IScopedRolePrimitive } from "./Role.model";
import { IUserContextPermission } from "../../system/User.model";

interface IUserService extends ICrudService<IUser> {
	
	login(user: string, pwd: string): Promise<IValidatedCredentials | null | undefined>;
	setPassword(passwordOverwrite: IPasswordOverwrite): Promise<void>;
	changePassword(passwordChange: IPasswordChange): Promise<void>;
	getUserRoles(userId: number): Promise<IScopedRolePrimitive[]>;
	setUserRoles(userId: number, roles: IScopedRolePrimitive[]): Promise<void>;
	setShares(resourceId: number, shares: ISharePrimitive[]): Promise<void>;
	setSharesFS(library: string, path: string, shares: ISharePrimitive[]): Promise<void>;
	getShares(resourceId: number): Promise<ISharePrimitive[]>;
	getSharesFS(library: string, path: string): Promise<ISharePrimitive[]>;
	getUserData(): Promise<any>;
	setUserData(key: string, data: any): Promise<void>;
	addSharesFS(library: string, path: string, shares: ISharePrimitive[], recursive: boolean): Promise<void>;
	delSharesFS(library: string, path: string, shares: ISharePrimitive[], recursive: boolean): Promise<void>;
	//getAllPermissions(): Promise<IPermission[]>;
	//setUserPermissions(permissionChange: IPermissionChange): Promise<void>;
	//groupByModule(permissions: IPermission[]): IPermission[][];
}

export interface AccessList {
	user: IUser,
	roles: IScopedRole[],
}

export interface ISharePrimitive {
	mode: "READ_ONLY" | "READ_WRITE",
	userId: number
}

// @ts-ignore
window.setUserData = (key: string, data: any) => {
	(new UserService()).setUserData(key, data);
	console.log("DigiPanel: User data has been set.");
}

class UserService implements IUserService {

	private encodePath(path: string) {
		// It makes no sense since the URL will look/like%2Fthis with just the FIRST
		// slash normal and then encoded slashes for the rest but the API requires it
		// like this and I'm too tired to argue with Vlasta so whatever.
		return "/" + encodeURIComponent(path.substr(1));
	}

    async addSharesFS(library: string, path: string, shares: ISharePrimitive[], recursive: boolean): Promise<void> {
		for (let i = 0; i < shares.length; i++)
			await http.post<object>("/resource/fs/" + library + this.encodePath(path) + "/share" + "?recursive=" + (recursive ? "true" : "false"), shares[i]);
	}

    async delSharesFS(library: string, path: string, shares: ISharePrimitive[], recursive: boolean): Promise<void> {
		for (let i = 0; i < shares.length; i++)
			await http.delete<object>("/resource/fs/" + library + this.encodePath(path) + "/share/" + shares[i].userId + "?recursive=" + (recursive ? "true" : "false"));
    }

	async getSharesFS(library: string, path: string): Promise<ISharePrimitive[]> {
		let obj = await http.get<object>("/resource/fs/" + library + this.encodePath(path));

		if (obj && obj.data && obj.data) {
			//return (await http.get<ISharePrimitive[]>("/resource/fs/" + library + this.encodePath(path) + "/share")).data;
			return await this.getShares((obj.data as any).recordId);
		}
		return [];
    }


	async setSharesFS(library: string, path: string, shares: ISharePrimitive[]): Promise<void> {
		// Step 1: Get current:
		let sharesNow = await this.getSharesFS(library, path);

		// Step 2: Revoke:
		let toRevoke = sharesNow.filter(x => !shares.find(y => y.userId === x.userId && y.mode === x.mode));
		for (let i = 0; i < toRevoke.length; i++)
			await http.delete<object>("/resource/fs/" + library + this.encodePath(path) + "/share/" + toRevoke[i].userId);

		// Step 3: Grant:
		let toGrant = shares.filter(x => !sharesNow.find(y => y.userId === x.userId && y.mode === x.mode));
		for (let i = 0; i < toGrant.length; i++)
			await http.post<object>("/resource/fs/" + library + this.encodePath(path) + "/share", toGrant[i]);
    }

	async getUserRoles(userId: number): Promise<IScopedRolePrimitive[]> {
		return (await http.get<IScopedRolePrimitive[]>("/user/" + userId + "/roles")).data;
	}

	async getCurrentAccess(): Promise<AccessList> {
		var d = (await http.get<any>("/user/access")).data;
		return {
			roles: d.roles.map((r: any) => r.role ? r : { role: r, scope: null }),
			user: d.user
		};
	}

	async deleteCurrentAvatar(): Promise<void> {
		await http.delete<object>("/user/image");
		return;
	}

	async getShares(resourceId: number): Promise<ISharePrimitive[]> {
		return (await http.get<ISharePrimitive[]>("/resource/" + resourceId + "/share")).data;
	}

	async setShares(resourceId: number, shares: ISharePrimitive[]) {

		// Step 1: Get current:
		let sharesNow = await this.getShares(resourceId);

		// Step 2: Revoke:
		let toRevoke = sharesNow.filter(x => !shares.find(y => y.userId === x.userId && y.mode === x.mode));
		for (let i = 0; i < toRevoke.length; i++)
			await http.delete<object>("/resource/" + resourceId + "/share/" + toRevoke[i].userId);

		// Step 3: Grant:
		let toGrant = shares.filter(x => !sharesNow.find(y => y.userId === x.userId && y.mode === x.mode));
		for (let i = 0; i < toGrant.length; i++)
			await http.post<object>("/resource/" + resourceId + "/share", toGrant[i]);
	}

	async setUserRoles(userId: number, roles: IScopedRolePrimitive[]) {

		/*// Step 1: Get current roles of the user:
		let rolesNow = await this.getUserRoles(userId);

		// Step 3: Grant new roles:
		let toGrant = roles.filter(x => !rolesNow.find(y => y.role === x.role && y.scope === x.scope));
		for (let i = 0; i < toGrant.length; i++)
			await http.post<object>("/user/" + userId + "/grant-role", toGrant[i]);

		// Step 2: Revoke any old roles:
		let toRevoke = rolesNow.filter(x => !roles.find(y => y.role === x.role && y.scope === x.scope));
		for (let i = 0; i < toRevoke.length; i++)
			await http.post<object>("/user/" + userId + "/revoke-role", toRevoke[i]);*/

		let r = roles.map(x => { return x.scope ? { role: x.role, scope: x.scope } : x.role })
		await http.post<object[]>("/user/" + userId + "/set-roles", r);
	}

	async login(user: string, pwd: string) : Promise<IValidatedCredentials | null | undefined> {
		var creds = {} as ILoginCredentials;
		creds.login = user;
		creds.password = pwd;
		var output = await http.post("/auth/login", creds)
			.then((resp : AxiosResponse<IValidatedCredentials>) => {
				return resp.data as IValidatedCredentials;
			})
			.catch((err : AxiosError<{error : string}>) => {
				return null;
			});
		return output;
	}

	async getAll(pageQuery?: PageQuery, sort?: Sorting<IUser>, withRelatedData: boolean = true): Promise<IPagedResponse<IUser>> {
		const pq = pageQuery?.toString() ?? '';
		
		if (sort?.constructor.name === "Object")
			sort = new Sorting<IUser>(sort.field, sort.direction === "ascending");
			
		const sq = sort?.toString() ?? '';
		const query = combineQueries(pq, sq, `enrich=${withRelatedData}`);

		var resp = (await http.get<IPagedResponse<any>>(`/user?${query}`)).data;

		resp.data.forEach(usr => usr.roles = usr.roles.map((r : any) => r.role ? r : { role: r }));

		return resp;
	}

	async setUserData(key: string, data: any) {
		let all = await this.getUserData();
		all[key] = data;
		return (await http.post<any>("/user/data", all)).data;
	}

	async getUserData() {
		return (await http.get<any>("/user/data")).data;
	}
	
	async create(user: IUser): Promise<IUser> {
		return (await http.post<IUser>("/user", user)).data;
	}
	
	async delete(user: IUser): Promise<void> {
		await http.delete<void>("/user/" + user.id);
	}
	
	async edit(user: IUser): Promise<IUser> {
		let u = (await http.patch<IUser>("/user/" + user.id, this.pure(user))).data;

		/*if (user.permissions !== undefined) {
			await this.setUserPermissions({ id: user.id, permissions: user.permissions });
			u.permissions = user.permissions;
		}*/
		return u;
	}

	private pure(entity: IUser): IUser {
		let e = Object.assign({}, entity);
		delete e.roles;
		return e;
	}

	async changePassword(passwordChange: IPasswordChange): Promise<void> {
		await http.post<void, AxiosResponse<void>, IPasswordChange>("/user/change-password", passwordChange);
	}

	async setPassword(passwordOverwrite: IPasswordOverwrite): Promise<void> {
		await http.post<void>("/user/" + passwordOverwrite.id + "/set-password", passwordOverwrite);
	}

	async getCurrentUserPermissions(): Promise<IUserContextPermission[]> {
		var arr: IUserContextPermission[] = [];
		((await http.get<any>("/user/access")).data).roles!.map((r: any) => r.role ?
			r!.role.permissions!.map((p: any) => arr.push({ scopeId: r.scope?.id ?? 0, ...p })) :
			r!.permissions!.map((p: any) => arr.push({ scopeId: r.scope?.id ?? 0, ...p })));
		return arr;
	}
	
	/*async getUserPermissions(userId: number): Promise<IPermission[]> {
		//return (await http.get<IPermission[]>("/auth/user-permissions/" + userId)).data;
	}*/

	/*async setUserPermissions(permissionChange: IPermissionChange): Promise<void> {
		const data = {...permissionChange, permissions: permissionChange.permissions.map(p => p.permission)};
		await http.post<IPermission[]>("/auth/set-permissions/", data);
	}*/
	
	async getAllPermissions(): Promise<IPermission[]> {
		return (await http.get<IPermission[]>("/permission")).data;
	}

	createDefaultEntity = createDefaultUser;

	/*private permissionless(user: IUser): IUser {
		if (!user.permissions)
			return user;
		let u = {...user};
		delete u.permissions;
		return u;
	}*/

	groupByRestrictionThenModule(permissions: IPermission[]): IPermission[][][] {
		let groupedRestr = new Map<string, Map<string, IPermission[]>>();
		
		for (let p of permissions) {
			if (p.restriction === "COMPLEX")
				p.restriction = "USER_HIERARCHY";
			if (!groupedRestr.has(p.restriction))
				groupedRestr.set(p.restriction, new Map<string, IPermission[]>());

			if (!groupedRestr.get(p.restriction)!.has(p.module))
				(groupedRestr.get(p.restriction)!).set(p.module, []);

			(groupedRestr.get(p.restriction)!).get(p.module)!.push(p);
		}

		let ar = Array.from(groupedRestr.values());
		return ar.map(x => Array.from(x.values()));
	}
	
	groupByModule(permissions: IPermission[]): IPermission[][] {
		let grouped = new Map<string, IPermission[]>();

		for (let p of permissions) {
			if (!grouped.has(p.module))
				grouped.set(p.module, []);
			
			grouped.get(p.module)!.push(p);
		}
		var ar = Array.from(grouped.values())
		return ar;
	}
	

  /*getAll() {
    return http.get<Array<ITutorialData>>("/tutorials");
  }
  get(id: string) {
    return http.get<ITutorialData>(`/tutorials/${id}`);
  }
  create(data: ITutorialData) {
    return http.post<ITutorialData>("/tutorials", data);
  }
  update(data: ITutorialData, id: any) {
    return http.put<any>(`/tutorials/${id}`, data);
  }
  delete(id: any) {
    return http.delete<any>(`/tutorials/${id}`);
  }
  deleteAll() {
    return http.delete<any>(`/tutorials`);
  }
  findByTitle(title: string) {
    return http.get<Array<ITutorialData>>(`/tutorials?title=${title}`);
  }*/
}
export default new UserService();
export type { IUserService };