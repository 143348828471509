import { Box } from "@mui/system";
import { FieldProps, getIn } from "formik";
import { useTranslation } from "react-i18next";
import FileExplorer from "../modules/files/FileExplorer";
import { onFileChangeFormikAdapter } from "../modules/files/Files.model";
import ColorPicker from "./ColorPicker";
import { ILabeledFieldProps, triggerFormChange } from "./Formik.model";
import { FormControl, FormControlLabel, InputLabel, Switch } from "@mui/material";

interface IFilePickerProps<Values = any> extends FieldProps<string, Values> {
    filter?: string[]
    output?: 'singleFile' | 'fileOrFolder' | 'folder'
    disabled?: boolean
}

interface FieldPropsWithRO extends FieldProps {
    readonly?: boolean
    disabled?: boolean
}

interface ILabeledFieldPropsWithRO extends ILabeledFieldProps {
    readonly?: boolean
    disabled?:boolean
}

export function MediaPicker(props: IFilePickerProps) {
    return <FileExplorer readonly={props.disabled} {...props.field} folder="media" mode="input" output={props.output ?? 'singleFile'}
        filter={props.filter} onChange={(v) => onFileChangeFormikAdapter(v, props, '')} />;
}

export function ContentPicker(props: IFilePickerProps) {
    return <FileExplorer readonly={props.disabled} {...props.field} folder="content" mode="input" output={props.output ?? 'fileOrFolder'}
        filter={props.filter} onChange={(v) => onFileChangeFormikAdapter(v, props, '')} />;
}



interface IFilePickerFieldProps extends ILabeledFieldProps {
    filePicker: (props: IFilePickerProps) => JSX.Element,
    filter?: string[]
    output?: 'singleFile' | 'fileOrFolder' | 'folder'
}
export function FilePickerField(props: IFilePickerFieldProps) {
    const { t } = useTranslation();
    const hasError = getIn(props.form.touched, props.field.name) && getIn(props.form.errors, props.field.name);
    //console.log(props.filePicker);
    return <FormControl variant='outlined' fullWidth className={'file-picker-field' + (hasError ? ' error' : '')}
            sx={{ mb: 2, p: 2, border: 1, borderColor: hasError ? 'error.main' : 'divider', flexWrap: 'wrap', rowGap: 1 }}>

        <InputLabel htmlFor={props.field.name} shrink variant='outlined' error={hasError} sx={{ ml: '-.4em', p: '0 .4em', bgcolor: 'background.paper' }}>
            {t(`${props.namespace}.${props.labelKey}`, {ns: props.namespace})}
        </InputLabel>

        {props.filePicker(props)}
    </FormControl>;
}


function _ColorPicker(props: FieldPropsWithRO) {
    return <ColorPicker readonly={ props.disabled } {...props.field} onChange={(v) => triggerFormChange(props.form, props.field.name, v)} />;
}

export function ColorPickerField(props: ILabeledFieldPropsWithRO) {
    const { t } = useTranslation();
    let sx = {};
    if (props.readonly || props.disabled)
        sx = { opacity: 0.6 };
    return <Box className='color-picker-field' sx={{ borderColor: 'divider' }}>
        <label style={sx} htmlFor={props.field.name}>{t(`${props.namespace}.${props.labelKey}`, {ns: props.namespace})}</label>
        {_ColorPicker(props)}
    </Box>;
}


export function SwitchField(props: ILabeledFieldPropsWithRO) {
    const { t } = useTranslation();

    return <FormControl fullWidth sx={{ mb: 1 }}>
        <FormControlLabel label={t(`${props.namespace}.show-title`, { ns: props.namespace })} control={
            <Switch disabled={props.disabled } {...props.field} />
        } />
    </FormControl>;
}