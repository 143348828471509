import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TagService from './Tags.service';
import { ITag } from './Tags.model';
import { FieldInputProps, FormikProps } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import Tags from './Tags';
import { triggerFormChange } from '../../system/Formik.model';


interface IState { 
	tags: ITag[]
}

type MutuallyExclude<T, E extends keyof T> = {
	[K in E]: { [P in K]: T[P] } & {
		[P in Exclude<E, K>]?: never;
	} & Omit<T, E>;
}[E];

type ExclusiveProps = 'field' | 'value';

interface IProps extends WithTranslation {
	field: FieldInputProps<ITag[]>,
	value: ITag[],
    form?: FormikProps<any>, // can be used versatilely
    labelKey?: string,
    namespace?: string,
	className?: string,
	disabled?: boolean,
	onChange?: (tags: ITag[]) => void
}

class TagSelect extends React.Component<MutuallyExclude<IProps, ExclusiveProps>, IState, WithTranslation> {

	constructor(props: MutuallyExclude<IProps, ExclusiveProps>) {
		super(props);
		this.state = {
			tags: []
		}

		this.setSelected = this.setSelected.bind(this);
		this.tagsEqual = this.tagsEqual.bind(this);
	}

	async componentDidMount() {
		var all = (await TagService.getAll()).data;
		this.setState({
			tags: all,
		});
	}

	tagsEqual(a: ITag, b: ITag) {
		return a.id === b.id;
	}

	setSelected(tags?: ITag[]) {
		if (this.props.form)
			triggerFormChange(this.props.form, this.props.field?.name || "", tags, 'tagSelect');
		if (this.props.onChange && typeof(tags) !== 'undefined')
			this.props.onChange(tags);
	}

	render() {
		return <Box className={'tagSelect ' + (this.props.className || "")}>
			{ this.state.tags === null && (<Box sx={{ display: 'flex' }}>
				<CircularProgress />
			</Box>) }
		
			{ this.state.tags && <Autocomplete
				options={this.state.tags}
				isOptionEqualToValue={this.tagsEqual}
				getOptionLabel={(tag: ITag) => tag.name}
				autoHighlight
				filterSelectedOptions
				multiple

				disabled={this.props.disabled}
				value={typeof (this.props.field) !== 'undefined' ? this.props.field?.value : this.props.value}
				onChange={(_event, value, _reason, _details) => this.setSelected(value)}

				renderInput={(params) =>
				<TextField variant='outlined'
					{...params}
					fullWidth
					label={this.props.labelKey ? this.props.t(`${this.props.namespace}.${this.props.labelKey}`, {ns: this.props.namespace}) : undefined}
					placeholder={this.props.t(`${Tags.getLocale()}.tag-input-help`, {ns: Tags.getLocale()})}
					className='entityInputField'
					inputProps={{ ...params.inputProps, autoComplete: 'off' }} />
				}
			/>}
		</Box>
	}
}

export default hoistStatics(withTranslation()(TagSelect), TagSelect)