import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { /*canEditSchedule, canEditSlides,*/ createDefaultSlide, IAddFilesRequest, IPlaylist, isValid, toMedia } from "./Playlists.model";
import hoistStatics from "hoist-non-react-statics";
import { Button, Tab, Tabs, Typography } from "@mui/material";
import DataTable, { Attribute } from "../../system/DataTable";
import PlaylistService from "./Playlist.service";
/*import SlideshowIcon from '@mui/icons-material/Slideshow';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';*/
import { Modal } from "../../system/Modal";
import SlidesChange from "./SlidesChange";
import ScheduleChange from "./ScheduleChange";
import IModule, { ModuleState } from "../../system/IModule";
import { load, save, setPageTitle } from '../../App';
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model';
import { IEntry, ISearchResult } from "../../system/SearchResult.model";
import { AuthContext } from "../../system/Base";
import { filterToExtension, hasCorrectExtension, mediaFilter } from "../files/Files.model";
import { ENTITY_VISIBILITY_TYPE } from "../../system/CRUDTable";

import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { IPermission } from "../users/Users.model";
import { ITableEntity } from "../../system/ICrudService";
import UserStrip from "../profile/UserStrip";
import BurstModeIcon from '@mui/icons-material/BurstMode';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface IState {
	editedPlaylist: IPlaylist | null,
	editingSlides: boolean,
	editingSchedule: boolean,
	contentAreas: string[],
	tabIndex: number,
	seed: number,
	showReadOnly: boolean,
}
interface IProps extends WithTranslation { }

@IModule
class Playlists extends React.Component<IProps, IState, WithTranslation> {

	dataTableRef: React.RefObject<DataTable<IPlaylist>>;
	playlistService = PlaylistService;

	constructor(props: IProps) {		
		super(props);
		this.state = {
			editedPlaylist: null,
			editingSlides: false,
			editingSchedule: false,
			contentAreas: [],
			tabIndex: 1,
			seed: 0,
			showReadOnly: false
		};

		this.showSlidesForm = this.showSlidesForm.bind(this);
		this.showScheduleForm = this.showScheduleForm.bind(this);
		this.hideSlidesForm = this.hideSlidesForm.bind(this);
		this.hideScheduleForm = this.hideScheduleForm.bind(this);
		this.triggerRefresh = this.triggerRefresh.bind(this);

		this.dataTableRef = React.createRef<DataTable<IPlaylist>>();
	} 
	
	public static getLocale() { return "module.playlists"; }
	
	async componentDidMount()
	{
		setPageTitle(this.props.t( Playlists.getLocale()+".module-title", { ns: Playlists.getLocale() }));
		
		const contentAreas = await this.playlistService.getContentAreas();
		this.setState({ contentAreas });

		let value = load('use-files-in-playlist');
		if (value) {
			save('use-files-in-playlist', null); // Clear
			const request = JSON.parse(value) as IAddFilesRequest;
			if (request && isValid(request))
				await this.addFilesToPlaylist(request);
		}
	}

	async addFilesToPlaylist(request: IAddFilesRequest) {
		let p: IPlaylist | undefined;
		if (request.isNew)
			p = await this.playlistService.create({ name: request.playlist });
		else
			p = (await this.playlistService.getAllMerged()).find(p => p.entity.name === request.playlist)?.entity;

		if (!p) return;
		
		const extensions = filterToExtension(mediaFilter);
		const mediaFiles = request.files.filter(filename => hasCorrectExtension(filename, extensions));

		const newSlides = mediaFiles.map(path => {
			const slide = toMedia(createDefaultSlide());
			slide.url = path;
			return slide;
		});
		p.slides = (p.slides ?? []).concat(newSlides);
		this.setState({ editedPlaylist: p, editingSlides: true, editingSchedule: false });
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Playlists.getLocale()+".module-title", {ns:Playlists.getLocale()})) || "???",
			route : "/playlists",
			icon : <PlaylistPlayIcon />,
			weight : 70
		};
	}
	
	public static async search(input : string) {
		const res = await PlaylistService.search(input);		
		return res.map((r: IEntry<IPlaylist>) : ISearchResult => { return {
			title : r.entity.name,
			description : '', // there's not much else to use for description...
			icon : <PlaylistPlayIcon />,
			link : "/playlists?search-result-index=" + r.index
		}});
	}
	
	public static isEnabled (auth: IUserContext) {
		if (!hasController(auth, controllerKeys.playlist))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.playlist.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}

	showSlidesForm(playlist: IPlaylist, readOnly: boolean) {
		this.setState({
			editedPlaylist: playlist,
			editingSlides: true,
			showReadOnly: readOnly
		});
	}

	async hideSlidesForm() {
		await this.triggerRefresh();
		
		this.setState({
			editedPlaylist: null,
			editingSlides: false,
			showReadOnly: false
		});
	}

	showScheduleForm(playlist: IPlaylist, readOnly: boolean) {
		this.setState({
			editedPlaylist: playlist,
			editingSchedule: true,
			showReadOnly: readOnly
		});
	}

	async hideScheduleForm() {
		await this.triggerRefresh();
		
		this.setState({
			editedPlaylist: null,
			editingSchedule: false,
			showReadOnly: false
		});
	}

	async triggerRefresh() {
		await this.dataTableRef.current?.refresh();
	}
	
	render() {
		const t = this.props.t;
		const ns = Playlists.getLocale();

		const modalWidth = { xs: 'min(90vw, 360px)', md: 'auto' };
		const modalHeight = '90vh';

		const SlidesChangeAction = (p: IPlaylist, k: keyof IPlaylist, perms: IPermission[] | undefined) => <div>
			<AuthContext.Consumer>{(auth) => {
				if (isAuthorized(perms!, permissions.playlist.setSlides))
					return <Button variant="outlined" type="submit" onClick={() => this.showSlidesForm(p, false)} startIcon={<BurstModeIcon />}>
						{t(`${ns}.edit-slides`, {ns: ns})}
					</Button>
				else
					return <Button variant="outlined" type="submit" onClick={() => this.showSlidesForm(p, true)} startIcon={<BurstModeIcon />}>
						{t(`${ns}.show-slides`, { ns: ns })}
					</Button>
			}}
			</AuthContext.Consumer>
		</div>;

		const ScheduleChangeAction = (p: IPlaylist, k: keyof IPlaylist, perms: IPermission[] | undefined) => <div>
			<AuthContext.Consumer>{(auth) => {
				if (isAuthorized(perms!, permissions.playlist.setSchedule))
					return <Button variant="outlined" type="submit" onClick={() => this.showScheduleForm(p, false)} startIcon={<AccessTimeIcon />}>
						{t(`${ns}.edit-schedule`, { ns: ns })}
					</Button>
				else
					return <Button variant="outlined" type="submit" onClick={() => this.showScheduleForm(p, true)} startIcon={<AccessTimeIcon />}>
						{t(`${ns}.show-schedule`, { ns: ns })}
					</Button>
						
			}}
			</AuthContext.Consumer>
		</div>;

		const AuthorField = (p: IPlaylist, k: keyof IPlaylist, perms: IPermission[] | undefined, fullEntity: ITableEntity<IPlaylist> | undefined) => <>
			{fullEntity?.access?.record.owner &&
				<UserStrip user={fullEntity?.access?.record.owner!} />
			}
		</>

		return (<>
			<Typography variant="h2">
				{ t(Playlists.getLocale()+".module-title", {ns:Playlists.getLocale()}) }
			</Typography>

			<Tabs value={this.state.tabIndex} onChange={(_, i) => { this.setState({ tabIndex: i, seed: this.state.seed + 1 }); }} centered sx={{
				background: 'linear-gradient(0deg, rgba(128,128,128,0.1), transparent)',
				margin: '0px -25px'
			}}>
				<Tab icon={<FolderSpecialIcon />} label={t("owntype.owned", { ns: "common" })} />
				<Tab icon={<FolderSharedIcon />} label={t("owntype.supervised", { ns: "common" })} />
				<Tab icon={<DriveFileMoveIcon />} label={t("owntype.shared", { ns: "common" })} />
			</Tabs>

			<DataTable ref={this.dataTableRef} service={PlaylistService} refreshSeed={this.state.seed}
				ownMode={this.state.tabIndex === 0 ? ENTITY_VISIBILITY_TYPE.OWNED : (this.state.tabIndex === 1 ? ENTITY_VISIBILITY_TYPE.SUPERVISED : ENTITY_VISIBILITY_TYPE.SHARED)}
				entityName={t(`${ns}.entity`, { ns: ns })} entityType='playlists'
				permissions={permissions.playlist} create={this.state.tabIndex === 0} edit={true} delete={true} share={this.state.tabIndex <= 1} changeOwner={this.state.tabIndex <= 1}>
				<Attribute name="id" type="number" labelKey="id" namespace={ns} hideInCreateForm hideInUpdateForm hideInTable />
				<Attribute name="name" labelKey="name" namespace={ns} />
				<Attribute name="slides" labelKey="slides" namespace={ns} sortable={false} hideInCreateForm hideInUpdateForm
					tableValueResolver={SlidesChangeAction} />
				<Attribute name="schedule" labelKey="schedule" namespace={ns} sortable={false} hideInCreateForm hideInUpdateForm
					tableValueResolver={ScheduleChangeAction} />
				<Attribute labelKey="owner" namespace="common" sortable={false} hideInCreateForm hideInUpdateForm
					tableValueResolver={AuthorField} hideInTable={this.state.tabIndex === 0} />
			</DataTable>

			{this.state.editedPlaylist && this.state.editingSlides &&
				<Modal title={(this.state.showReadOnly? '': t(`${ns}.edit-slides`, {ns: ns }) + ': ') + this.state.editedPlaylist.name}
				isOpen={true} onClose={this.hideSlidesForm} width={modalWidth} height={modalHeight}>
				<SlidesChange readOnly={this.state.showReadOnly} entity={this.state.editedPlaylist} onDone={this.hideSlidesForm} />
			</Modal>}
		
			{this.state.editedPlaylist && this.state.editingSchedule &&
				<Modal title={(this.state.showReadOnly ? '' : t(`${ns}.edit-schedule`, {ns: ns}) + ': ') + this.state.editedPlaylist.name}
				isOpen={true} onClose={this.hideScheduleForm} width={modalWidth} height={modalHeight}>
				<ScheduleChange readOnly={this.state.showReadOnly}  entity={this.state.editedPlaylist} onDone={this.hideScheduleForm}/>
			</Modal>}
			</>);
    }
}

export default hoistStatics(withTranslation()(Playlists), Playlists)