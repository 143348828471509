import React from "react";
import hoistStatics from "hoist-non-react-statics";
import { withTranslation, WithTranslation } from "react-i18next";
import { ListSubheader, MenuItem } from "@mui/material";
import { Field, FieldInputProps, FormikProps } from "formik";
import { IBoard } from "../boards/Boards.model";
import MuiField from "../../system/MuiField";
import { ButtonMount, IButton } from "./Button.model";
import { imageFilter } from "../files/Files.model";
import { ContentPicker, FilePickerField, MediaPicker } from "../../system/Formik.AdaptedFields";
import { IRelevantBoard } from "../kiosks/Kiosks.service";


interface IState {}

interface IProps extends WithTranslation {
    showPosition: boolean,
    boards: IRelevantBoard[],
    field: FieldInputProps<IButton>,
    form: FormikProps<any>
}

class ButtonFields extends React.Component<IProps, IState, WithTranslation> {
    
    mountTypes = [ ButtonMount.Local, ButtonMount.Global, ButtonMount.Combined, ButtonMount.Url, ButtonMount.LocalBoard, ButtonMount.GlobalBoard ];

    constructor(props: IProps) {
        super(props);

        this.state = {};
    }

    render() {
        const t = this.props.t;
        const ns = "module.dashboard";
        const button = this.props.field.value;
        const namePrefix = this.props.field.name ? this.props.field.name + '.' : '';

        return <>
            {this.props.showPosition && 
                <Field name={`${namePrefix}position`} labelKey="button-position" namespace={ns} component={MuiField} />}
            <Field name={`${namePrefix}label`} labelKey="button-label" namespace={ns} component={MuiField} />
            <Field name={`${namePrefix}mount`} labelKey="button-mount" namespace={ns} component={MuiField} variant='select'>
                {this.mountTypes.map((type) =>
                    <MenuItem key={type} value={type}>
                        {t(`${ns}.${type}`, {ns: ns})}
                    </MenuItem>
                )}
            </Field>
            {(button.mount === ButtonMount.Local || button.mount === ButtonMount.Url) && <Field
                name={`${namePrefix}path`} labelKey="target-path" namespace={ns} component={MuiField} />}
            
            {(button.mount === ButtonMount.Global || button.mount === ButtonMount.Combined) && <Field
                name={`${namePrefix}path`} labelKey='target-path' namespace={ns}
                    output='folder' filePicker={ContentPicker} component={FilePickerField} />}

            {(button.mount === ButtonMount.LocalBoard) && <Field
                name={`${namePrefix}path`} labelKey="board-name" namespace={ns} component={MuiField} />}

            {(button.mount === ButtonMount.GlobalBoard) && <Field
                name={`${namePrefix}path`} labelKey="board-name" namespace={ns} component={MuiField} variant='select'>
                <ListSubheader>{t('module.boards.relevant-boards', {ns: "module.boards"}) }</ListSubheader>
                {this.props.boards.filter(x => x.relevance > 0).map((board: IRelevantBoard) =>
                    <MenuItem key={board.entity.id} value={board.entity.title}>
                        {board.entity.title}
                    </MenuItem>
                )}
                <ListSubheader>{t('module.boards.other-boards', { ns: "module.boards" })}</ListSubheader>
                {this.props.boards.filter(x => x.relevance <= 0).map((board: IRelevantBoard) =>
                    <MenuItem key={board.entity.id} value={board.entity.title}>
                        {board.entity.title}
                    </MenuItem>
                )}
            </Field>   }
            
            <Field name={`${namePrefix}icon`} labelKey='icon' namespace={ns}
                filter={imageFilter} filePicker={MediaPicker} component={FilePickerField} />
        </>;                    
    }
}

export default hoistStatics(withTranslation()(ButtonFields), ButtonFields);