import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import UserService from './Users.service';
import Checkbox from '@mui/material/Checkbox';
import { IPermission } from './Users.model';
import { FieldProps } from 'formik';
import { Alert, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, Tooltip } from '@mui/material';
import { ExpandLess, ExpandMore, Hexagon } from '@mui/icons-material';
import { triggerFormChange } from '../../system/Formik.model';
import { WithTranslation, withTranslation } from 'react-i18next';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import StyleIcon from '@mui/icons-material/Style';
import PentagonIcon from '@mui/icons-material/Pentagon';

interface IState { 
	allPermissions : IPermission[][][],
	expandedModule: string,
	selected: IPermission[],
	tabIndex: string,
	tabIndexI: number
}

interface IProps extends FieldProps<IPermission[]>, WithTranslation {
}

class PermissionSelect extends React.Component<IProps, IState> {

	constructor(props : IProps) {
		super(props);

		this.state = {
			allPermissions: [],
			expandedModule: '',
			selected: this.props.field.value ?? [],
			tabIndex: '',
			tabIndexI: 0
		}

		this.hasPermission = this.hasPermission.bind(this);
		this.togglePermission = this.togglePermission.bind(this);
	}


	
	async componentDidMount() {
		const allPerms = UserService.groupByRestrictionThenModule(await UserService.getAllPermissions());

		// TODO: TRANSFORM all restriction = "COMPLEX" to restriction = "USER_HIERARCHY". Then translate it. Then test it in table board.

		this.setState({
			allPermissions: allPerms,
			tabIndex: 'GLOBAL',
			tabIndexI: 0
		});
	}

	hasPermissions(module: IPermission[], restriction: string): boolean | undefined {
		const all = module.every(p => this.hasPermission(p));
		const any = module.some(p => this.hasPermission(p));
		if (all) return true;
		if (!any) return false;
		return undefined;
	}

	hasPermission(permission: IPermission): boolean {
		return this.state.selected.filter(p => p.permission === permission.permission).length > 0 ?? false;
	}

	togglePermissions(module: IPermission[], restriction: string) {
		const hasPerms: boolean | undefined = this.hasPermissions(module, restriction);

		const without = this.state.selected.filter((p) => !(p.module === module[0].module && p.restriction === restriction)) ?? [];
		if (hasPerms === true) {
			this.setSelected(without);
		} else {
			const selected = without.concat(module);
			this.setSelected(selected);
		}
	}

	togglePermission(permission: IPermission) {
		if (!this.hasPermission(permission)) {
			const selected = this.state.selected !== undefined
				? [...this.state.selected, permission]
				: [ permission ];
			this.setSelected(selected);
		} else {
			const selected = this.state.selected.filter((p) => p.permission !== permission.permission);
			this.setSelected(selected);
		}
	}

	toggleModuleOpen(module: string) {
		this.setState({ expandedModule: this.state.expandedModule === module ? '' : module });
	}

	setSelected(permissions: IPermission[]) {
		this.setState({ selected: permissions });
		triggerFormChange(this.props.form, this.props.field.name, permissions, 'permissionSelect');
	}

	findRestr(x: string): IPermission[][] {
		if (x === "USER_HIERARCHY")
			return this.state.allPermissions.find(p => p[0].find(y => y.restriction === x)) || new Array([]);
		else
			return this.state.allPermissions.find(p => p[0].find(y => y.restriction === x)) || new Array([]);
	}

	findRestrIndex(x: string): number {
		let ar = [];
		for (let i = 0; i < this.state.allPermissions.length; i++)
			ar.push(this.state.allPermissions[i][0][0].restriction);
		return ar.indexOf(x) || 0;
	}

	indexToRestr(x: number): string {
		return [
			'GLOBAL',
			'USER_HIERARCHY',
			'TAG_SCOPE'
		][x];
	}

	permText(module: string, x: string) {
		const t = this.props.t;
		let p = x.toLowerCase().replaceAll('_', '-');
		let s = '';
		if (p.startsWith('manage-area-')) {
			s = x.substring('manage-area-'.length);
			p = 'manage-area';
		}
		let tr = t('module.users.permission.specific.' + p, { ns: 'module.users' }) + s;
		if (module.toLowerCase() === "global")
			return tr.replace(/\[.*?\]/g, '');
		else
			return tr.replace(/\[|\]/g, '');
	}

	render() {
		//const hasPermissions = this.state.allPermissions?.map((tab) => tab.map((module) => this.hasPermissions(module)));
		const t = this.props.t;

		const permPage = (restrPage: IPermission[][]) => {
			if (!(restrPage && restrPage.length && restrPage[0].length))
				return null;
			return <List sx={{ p: 0 }}>
				{(restrPage && restrPage.length && restrPage[0].length) &&
					<Alert sx={{ mb: '1em' }} severity="info">{t('module.users.permission.restriction.' + restrPage[0][0].restriction.toLowerCase().replaceAll('_', '-') + '.info', { ns: 'module.users' })}</Alert>}
				{restrPage && restrPage.length &&
					restrPage.map((module, mindex) => {
						const hasPermissions = (this.state.allPermissions[this.findRestrIndex(this.state.tabIndex)] || []).map((module) => this.hasPermissions(module, module[0].restriction));
						return (<div key={(module[0] && module[0].module) || "NO_MODULE"}>
							<ListItem
								disablePadding
								secondaryAction={
									<Checkbox
										edge="end"
										checked={Boolean(hasPermissions[mindex])}
										indeterminate={hasPermissions[mindex] === undefined}
										tabIndex={-1}
										disableRipple
										onChange={() => this.togglePermissions(module, module[0].restriction)}
									/>
								}>
								<ListItemButton onClick={() => this.toggleModuleOpen(module[0].module)} dense>
									<ListItemIcon>
										{module[0] && this.state.expandedModule === module[0].module ? <ExpandLess /> : <ExpandMore />}
									</ListItemIcon>
									<ListItemText primary={module[0] && t('module.users.permission.controller.' + module[0].module.toLowerCase(), { ns: 'module.users' })} />
								</ListItemButton>
							</ListItem>

							<Collapse sx={{ ml: '3em' }} in={module[0] && this.state.expandedModule === module[0].module} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>

									{module.map((permission) => (
										<ListItemButton dense
											key={permission.permission}
											sx={{ height: '36px' }}
											onClick={() => this.togglePermission(permission)}>
											<ListItemIcon>
												<Checkbox
													size="small"
													checked={this.hasPermission(permission)}
													tabIndex={-1}
													disableRipple
												/>
											</ListItemIcon>
											<ListItemText primary={this.permText(permission.restriction, permission.permission)} />
										</ListItemButton>
									))}
								</List>
							</Collapse>
						</div>
						)
					})}
			</List>
	}

		return <>
		{ !this.state.allPermissions && (<Box sx={{ display: 'flex' }}>
			<CircularProgress />
		</Box>) }
			{this.state.allPermissions && (<Box sx={{ display: 'flex' }}>
				<Tabs value={this.state.tabIndexI} orientation='vertical' onChange={(_, i) => {
					this.setState({ tabIndex: this.indexToRestr(i), tabIndexI: i, expandedModule: '' });
				}} sx={{ borderRight: 1, borderColor: 'divider' }}>
					<Tab icon={<PentagonIcon />} label={t('module.users.permission.restriction.global.title', { ns: 'module.users' })} />
					<Tab icon={<AccountTreeIcon />} label={t('module.users.permission.restriction.user-hierarchy.title', { ns: 'module.users' })} />
					{
						permPage(this.findRestr("TAG_SCOPE")) && /* Only if there is something (not local admin) */
						<Tab icon={<StyleIcon />} label={t('module.users.permission.restriction.tag-scope.title', { ns: 'module.users' })} />
					}
				</Tabs>
				{ 
					this.state.tabIndex && <Box sx={{width: '450px', minHeight: '550px'}}>{permPage(this.findRestr(this.state.tabIndex))}</Box>
				}
			</Box>)}
		</>
	}
}

const PermissionSelectF2 = (props: IProps) => {
	return <PermissionSelect {...props}></PermissionSelect>
}

const PermissionSelectF = withTranslation()(PermissionSelectF2);

export { PermissionSelectF }
export default withTranslation()(PermissionSelect)