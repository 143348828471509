import { Button, Tooltip } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import ShareIcon from '@mui/icons-material/Share'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import React from "react";
import { Translation, WithTranslation, withTranslation } from "react-i18next";
import SaveCloseButton from "./SaveCloseButton";
import BoltIcon from '@mui/icons-material/Bolt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface IState { }

const SHOW_ONLY_ICONS_ON_CRUD_BUTTONS = false;

interface IProps extends WithTranslation {
    variant: 'contained' | 'outlined',
    role?: 'create' | 'update' | 'delete' | 'share' | 'changeowner' | 'show' | 'close',
    isModal?: boolean,
    inTable?: boolean
    disabled?: boolean,
    onClick?: () => void, //React.MouseEventHandler<HTMLButtonElement>,
}

 class CRUDButton extends React.Component<IProps, IState, WithTranslation> {
    static defaultProps = { disabled: false };

	render() {
        const icon = this.props.role === 'create' ? <AddIcon />
        : this.props.role === 'update' ? <EditIcon />
        : this.props.role === 'share' ? <ShareIcon />
        : this.props.role === 'delete' ? <DeleteIcon />
        : this.props.role === 'show' ? <VisibilityIcon />
        : this.props.role === 'close' ? <VisibilityOffIcon />
        : this.props.role === 'changeowner' ? <AccountCircleIcon />
                                    : <BoltIcon />;

        const icoOnly = (this.props.inTable && SHOW_ONLY_ICONS_ON_CRUD_BUTTONS);

        if (this.props.isModal && (!this.props.role || this.props.role === 'update')) {
            return <SaveCloseButton disabled={this.props.disabled} onClick={() => { this.props.onClick?.(); }} startIcon={icon} role={ (this.props.role === 'update' ? 'update' : 'save') } />
        } else {
            return <Tooltip title={this.props.t(this.props.role ? "crud." + this.props.role : "common.doit")}><Button className={"crud-button" + (icoOnly ? " ico-only" : "")} variant={this.props.variant} type="submit" disabled={this.props.disabled} onClick={this.props.onClick} startIcon={icoOnly ? undefined : icon}>
                {!icoOnly && <Translation>{t => t(this.props.role ? "crud." + this.props.role : "common.doit")}</Translation>}
                { icoOnly && icon }
            </Button></Tooltip>;
        }
    }
}

const TranslatedCRUDButton = withTranslation()(CRUDButton);
export { TranslatedCRUDButton as CRUDButton };