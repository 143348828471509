import { IPermission } from "../modules/users/Users.model";

export interface IUserContextController {
	key : string,
	name : string,
	author : string,
	version : string,
	deployment : string
}

interface IPermissionForCheck {
	scopeId: number | undefined,
	permission: string
}

export interface IUserContextPermission {
	module : string,
	restriction : string,
	permission: string,
	scopeId: number
}

export interface IUserContext {
	name : string,
	id : number,
	controllers : IUserContextController[],
	permissions : IUserContextPermission[],
}

export class UserContext implements IUserContext {
	name = "??";
	id = -1;
	controllers :  IUserContextController[] = [];
	permissions : IUserContextPermission[] = [];
}

export const permissions = {
	resource: {
		readInfo: "READ_RESOURCE_INFO",
		changeOwner: "CHANGE_RESOURCE_OWNER",
		share: "SHARE_RESOURCE",
		unshare: "UNSHARE_RESOURCE"
	},
	auth: {
		modifyPermissions: "MODIFY_PERMISSIONS",
	},
	board: {
		create: "CREATE_BOARD",
		read: "READ_BOARD",
		update: "UPDATE_BOARD",
		delete: "DELETE_BOARD",
		modifyTags: "MODIFY_BOARD_TAGS"
	},
	configuration: {
		read: "READ_CONFIGURATION",
		write: "WRITE_CONFIGURATION",
	},
	content: {
		read: "READ_CONTENT",
		write: "WRITE_CONTENT",
	},
	kiosk: {
		create: "CREATE_KIOSK",
		read: "READ_KIOSK",
		update: "UPDATE_KIOSK",
		delete: "DELETE_KIOSK",
		modifyTags: "MODIFY_KIOSK_TAGS",
		modifyButtons: "MODIFY_KIOSK_BUTTONS",
		modifyUptimes: "MODIFY_UPTIME_SCHEDULE",
		modifyAudio: "MODIFY_AUDIO_SCHEDULE",
		reboot: "REBOOT_KIOSK",
		resync: "RESYNC_KIOSK",
		restart: "RESTART_APP",
		readSystem: "READ_KIOSK_SYSTEM",
		modifyWhitelist: "MODIFY_KIOSK_WHITELIST",
		modifyLogo: "SET_KIOSK_LOGO",
		modifyWifi: "CONNECT_TO_WIFI",
		modifyOrientation: "SET_SCREEN_ORIENTATION",
		startTouchInit: "START_TOUCH_INIT",
	},
	media: {
		read: "READ_MEDIA",
		write: "WRITE_MEDIA",
	},
	playlist: {
		create: "CREATE_PLAYLIST",
		read: "READ_PLAYLIST",
		update: "UPDATE_PLAYLIST",
		delete: "DELETE_PLAYLIST",
		manageArea: "MANAGE_AREA_",
		setSlides: "SET_PLAYLIST_SLIDES",
		setSchedule: "SET_PLAYLIST_SCHEDULE"
	},
	shared: {
		read: "READ_SHARED",
		write: "WRITE_SHARED"
	},
	tag: {
		create: "CREATE_TAG",
		read: "READ_TAG",
		update: "UPDATE_TAG",
		delete: "DELETE_TAG",
	},
	scope: {
		create: "CREATE_SCOPE",
		read: "READ_SCOPE",
		update: "UPDATE_SCOPE",
		delete: "DELETE_SCOPE",
	},
	user: {
		create: "CREATE_USER",
		read: "READ_USER",
		update: "UPDATE_USER",
		delete: "DELETE_USER",
		setPassword: "SET_USER_PASSWORD",
		grantRole: "GRANT_USER_ROLE",
		revokeRole: "REVOKE_USER_ROLE",
	},
	role: {
		create: "CREATE_ROLE",
		read: "READ_ROLE",
		update: "UPDATE_ROLE",
		delete: "DELETE_ROLE",
	},
	statistics: {
    	read: "READ_STATISTICS",
    	delete: "DELETE_STATISTICS",
	},
	notifications: {
		create: 'CREATE_SUBSCRIPTION',
		read: 'READ_SUBSCRIPTION',
		delete: 'DELETE_SUBSCRIPTION'
	}
};

export interface ICrudPermissions {
	create: string,
	read: string,
	update: string,
	delete: string
}

function isCtx(x: IUserContext | IPermission[]): x is IUserContext {
	return (x as IUserContext).id !== undefined;
}

export function isAuthorized(userContextOrPermissionArray: IUserContext | IPermission[], permission: string, scopeId?: number | undefined) {
	if (isCtx(userContextOrPermissionArray))
		return userContextOrPermissionArray.permissions.some(x => x.permission === permission && (!scopeId || scopeId === x.scopeId));
	else
		return userContextOrPermissionArray.some(x => x.permission === permission);
}

function permissionCheck(userContext: IUserContext,  permission: (string | IPermissionForCheck)) {
	let x = userContext.permissions.some(x => (x.permission === permission) || (typeof (permission) !== "string" && permission.permission === x.permission && (!permission.scopeId || permission.scopeId === x.scopeId)));
	return x;
}

export function isAuthorizedAny(userContext: IUserContext, ...permissions: (string | IPermissionForCheck)[]) {
	for (let i = 0; i < permissions.length; i++)
		if (permissionCheck(userContext, permissions[i]))
			return true;
	return false;
}

export function isAuthorizedAll(userContext: IUserContext, ...permissions: string[]) {
	for (let i = 0; i < permissions.length; i++)
		if (!permissionCheck(userContext, permissions[i]))
			return false;
	return true;
}

export const controllerKeys = {
	admin: "v1/admin",
	auth: "v1/auth",
	board: "v1/board",
	bulk: "v1/bulk",
	configuration: "v1/configuration",
	content: "v1/content",
	file: "v1/file",
	kiosk: "v1/kiosk",
	media: "v1/media",
	playlist: "v1/playlist",
	shared: "v1/shared",
	tag: "v1/tag",
	user: "v1/user",
	statistics: "v1/statistics"
};

export enum ControllerDeployment {
	Common = 'common', Global = 'global', Local = 'local'
}

export function hasController(userContext: IUserContext, controllerKey: string, deployment?: ControllerDeployment) {
	if (deployment)
		return userContext.controllers.some(x => x.key === controllerKey && x.deployment === deployment);
	return userContext.controllers.some(x => x.key === controllerKey);
}