import React from 'react';
import ICrudService, { ICrudServiceSmart, ITableEntity } from "./ICrudService";
import { CRUDTable, ENTITY_VISIBILITY_TYPE, ITableConfig } from "./CRUDTable";
import { IFormConfig, IErrors, IEntity } from "./CRUDForm";
import { CRUDAttribute, IAttribute } from "./CRUDAttribute";
import { IIdentifiable } from './Identifiable.model';
import { CRUDOperation } from './CRUDOperation';
import { Navigate } from 'react-router-dom';
/**
 * TODO: fix access to translations.
 * Using i18next directly is probably ugly. However:
 * - withTranslation() breaks type parameter checking
 * - useTranslation() can be used only in functional components
 * - <Trans> and <Translation> return Element, not string
 */
import i18next from 'i18next';
import { ICrudPermissions } from './User.model';

export { CRUDAttribute as Attribute }

interface IState {
	redirectingTo : string
}

interface IProps<TEntity extends IEntity> {
	service: ICrudService<TEntity> | ICrudServiceSmart<TEntity>
	entityName?: string
	entityType: string
	permissions: ICrudPermissions,

	customFilters?: string[],

	customLock?: (entity: TEntity, all: ITableEntity<TEntity>[]) => boolean

	ownMode?: ENTITY_VISIBILITY_TYPE,

	customEditMethod?: (e: TEntity, canEdit: boolean) => void

	create?: boolean
	createDescription? : string
	
	edit?: boolean
	editDescription? : string
	
	delete?: boolean
	deleteDescription?: string

	share?: boolean
	shareDescription?: string

	changeOwner?: boolean
	changeOwnerDescription?: string

	hideActions?: boolean

	firstRecordPermanent?: boolean
	
	onChange?: (e: TEntity, o: CRUDOperation) => void

	beforeSubmit?: (e: TEntity, o: CRUDOperation) => void

	validate?: (e: TEntity, o: CRUDOperation) => IErrors<TEntity>
	
	children: React.ReactElement<IAttribute<TEntity>>[],

	refreshSeed?: any,
}

/**
 * A convenience class that wraps the more general CRUDTable for typical usage.
 */
export default class DataTable<TEntity extends IIdentifiable & IEntity> extends React.Component<IProps<TEntity>, IState> {

	crudTableRef: React.RefObject<CRUDTable<TEntity>>;

	constructor(props : IProps<TEntity>) {
		super(props);
		this.state = {
			redirectingTo : ""
		};

		this.crudTableRef = React.createRef();

		this.selectEntry = this.selectEntry.bind(this);
		this.refresh = this.refresh.bind(this);
		this.getCreateFormConfig = this.getCreateFormConfig.bind(this);
		this.getUpdateFormConfig = this.getUpdateFormConfig.bind(this);
		this.getDeleteFormConfig = this.getDeleteFormConfig.bind(this);
		this.getShareFormConfig = this.getShareFormConfig.bind(this);
		this.getChangeOwnerFormConfig = this.getChangeOwnerFormConfig.bind(this);
	}

	getCreateFormConfig(): IFormConfig<TEntity> {
		return {
			title: i18next.t("crud.create") + ": " + (this.props.entityName ?? "???"),
			onSubmit: async (entity: TEntity) => {
				this.props.beforeSubmit?.(entity, CRUDOperation.Create);
				var r = await this.props.service.create(entity);
				this.props.onChange?.(r, CRUDOperation.Create);
				return r;
			},
			validate: this.props.validate
		};
	}

	getUpdateFormConfig(): IFormConfig<TEntity> {
		return {
			title: i18next.t("crud.update") + ": " + (this.props.entityName ?? "???"),
			message: this.props.editDescription,
			onSubmit: async (entity: TEntity) => {
				this.props.beforeSubmit?.(entity, CRUDOperation.Update);
				var r = await this.props.service.edit(entity);
				this.props.onChange?.(entity, CRUDOperation.Update);
				return r;
			},
			validate: this.props.validate
		};
	}

	getShowFormConfig(): IFormConfig<TEntity> {
		return {
			title: (this.props.entityName ?? "???"),
			message: "",
			onSubmit: (entity: TEntity) => { return Promise.resolve(); }
		};
	}

	getDeleteFormConfig(): IFormConfig<TEntity> {
		return {
			title: i18next.t("crud.delete") + ": " + (this.props.entityName ?? "???"),
			message: this.props.deleteDescription ?? i18next.t("crud.delete-ask"),
			onSubmit: async (entity: TEntity) => {
				this.props.beforeSubmit?.(entity, CRUDOperation.Delete);
				var r = await this.props.service.delete(entity);
				this.props.onChange?.(entity, CRUDOperation.Delete);
				return r;
			},
			validate: this.props.validate
		};
	}

	getShareFormConfig(): IFormConfig<TEntity> {
		return {
			title: i18next.t("crud.share") + ": " + (this.props.entityName ?? "???"),
			message: this.props.shareDescription,
			onSubmit: async (entity: TEntity) => {
				this.props.beforeSubmit?.(entity, CRUDOperation.Share);
				//var r = await this.props.service. (entity);
				this.props.onChange?.(entity, CRUDOperation.Share);
				//return r;
			},
			validate: this.props.validate
		};
	}

	getChangeOwnerFormConfig(): IFormConfig<TEntity> {
		return {
			title: i18next.t("crud.changeowner") + ": " + (this.props.entityName ?? "???"),
			message: this.props.changeOwnerDescription,
			onSubmit: async (entity: TEntity) => {
				this.props.beforeSubmit?.(entity, CRUDOperation.ChangeOwner);
				//var r = await this.props.service. (entity);
				this.props.onChange?.(entity, CRUDOperation.ChangeOwner);
				//return r;
			},
			validate: this.props.validate
		};
	}

	componentDidUpdate() {
		const urlParams = new URLSearchParams(window.location.search);
		const selectedIndex = urlParams.get('search-result-index');
		const sameEntity = window.location.pathname.includes(this.props.entityType);
		
		if (!this.state.redirectingTo && selectedIndex !== null && sameEntity) {
			urlParams.delete('search-result-index');
			let cleanUrl = window.location.pathname + urlParams.toString();
			
			// We are using a bit of a hack. We set the new url as a new state and let it render
			// which triggers the Navigation component. Then we remove new URL in the callback
			// of the first set state, which happens AFTER the first Navigation is triggered,
			// and it also prevents further navigation looping. So these lines are basically
			// just a redirect and nothing else. And in the second callback, finally open the record.
			this.setState({ redirectingTo : cleanUrl }, () => {
				this.setState({ redirectingTo : "" }, () => {
					this.selectEntry(Number(selectedIndex));
				});
			});
		}
	}
	
	async selectEntry(index: number) {
		await this.crudTableRef.current?.selectEntry(index);
	}

	async refresh() {
		await this.crudTableRef.current?.refresh();
	}

	render() {	
		return (<>
		{ this.state.redirectingTo && <Navigate to={this.state.redirectingTo} /> }
			<CRUDTable ref={this.crudTableRef}
				ownMode={this.props.ownMode}
				hideActions={this.props.hideActions || false}
				fetchItems={
					async (config: ITableConfig<TEntity>) => {
						if ('entity' in this.props.service) {
							// ICrudServiceSmart
							let result = await (this.props.service as ICrudServiceSmart<TEntity>).getAll(config.page, config.sort, this.props.ownMode, this.props.customFilters);
							return Promise.resolve(result);
						} else {
							// ICrudService
							let result = await (this.props.service as ICrudService<TEntity>).getAll(config.page, config.sort, this.props.ownMode, this.props.customFilters);
							return Promise.resolve(result);
						}
					}}
				permissions={this.props.permissions}
				createDefaultEntity={this.props.service.createDefaultEntity}
				createForm={this.props.create ? this.getCreateFormConfig() : null}
				showForm={this.getShowFormConfig()}
				updateForm={this.props.edit ? this.getUpdateFormConfig() : null}
				deleteForm={this.props.delete ? this.getDeleteFormConfig() : null}
				shareForm={this.props.share ? this.getShareFormConfig() : null}
				changeOwnerForm={this.props.changeOwner ? this.getChangeOwnerFormConfig() : null}
				firstRecordPermanent={this.props.firstRecordPermanent}
				refreshSeed={this.props.refreshSeed}
				customLock={this.props.customLock}
				customEditMethod={this.props.customEditMethod}
		>
		
		{ this.props.children }
		</CRUDTable>
		</>);
	}
}