import React, { useState } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, WithTranslation } from 'react-i18next';
import SplitButton from './SplitButton';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';

interface IState {
	recentlySaved: boolean,
	pref: number
}

interface IProps extends WithTranslation {
	onClick?: () => void;
	onClose?: () => void;
	disabled?: boolean;
	role?: 'save' | 'update';
	startIcon?: React.ReactNode;
}

class SaveCloseButton extends React.Component<IProps, IState, WithTranslation> {

	timeoutRecentlySaved: any;

	constructor(props: IProps) {
		super(props);

		this.state = {
			recentlySaved: false,
			pref: 0
		};
	}
	async markRecentlySaved() {
		this.setState({ recentlySaved: true });
		this.timeoutRecentlySaved = window.setTimeout(() => {
			this.setState({ recentlySaved: false });
			this.timeoutRecentlySaved = null;
		}, 2000);
	}


	cleanRecentlySavedInterval() {
		if (this.timeoutRecentlySaved) {
			window.clearInterval(this.timeoutRecentlySaved);
			this.timeoutRecentlySaved = null;
		}
	}

	componentDidMount() {
		let i = shouldSaveFromLocalStorage();
		this.setState({ pref: i?0:1 });
	}

	componentWillUnmount() {
		this.cleanRecentlySavedInterval();
	}

	render() {
		let opts = [this.props.t("common.save-and-close"), this.props.t("common.save-and-stay")];
		if (this.props.role === 'update') {
			opts = [this.props.t("common.update-and-close"), this.props.t("common.update-and-stay")];
		}

		return <ShouldCloseContext.Consumer>{ss => <SplitButton disabled={this.props.disabled} onSelectionChanged={(i) => {
			this.setState({ pref: i });
			localStorage.setItem('save-close-btn-prefer', i + '');
			ss.setShouldClose(i === 0);
		}}
			selectedIndex={this.state.pref} options={opts} onClick={(index) => {
				if (index === 0) {
					// Save and close
					//ss.setShouldClose(true);
					this.props.onClick?.();
					this.props.onClose?.();
				} else {
					// Save and stay
					//ss.setShouldClose(false);
					this.markRecentlySaved();
					this.props.onClick?.();
				}
			}} variant="contained" startIcon={!this.state.recentlySaved ? (this.props.startIcon || <SaveIcon />) : <span className="check-anim"><span className="check-anim-mask"><CheckIcon /></span></span>} />
		}</ShouldCloseContext.Consumer>
	}

}

const shouldSaveFromLocalStorage = ():boolean => Number(localStorage.getItem('save-close-btn-prefer')) !== 1;

export interface IShouldCloseContext {
	shouldClose: boolean,
	setShouldClose: (val: boolean) => void
}

export const ShouldCloseContextProvider = (props: any) => {

	const [v, sv] = useState(shouldSaveFromLocalStorage());

	const contextValue = {
		shouldClose: v,
		setShouldClose: (val: boolean) => {
			sv(val);
		}
	};

	return <ShouldCloseContext.Provider value={contextValue}>
		{ props.children }
	</ShouldCloseContext.Provider>
}

export const ShouldCloseContext = React.createContext<IShouldCloseContext>({
	shouldClose: true, setShouldClose: (val: boolean) => { }
});

export default hoistStatics(withTranslation()(SaveCloseButton), SaveCloseButton)