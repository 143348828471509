import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import GridViewIcon from '@mui/icons-material/GridView';
import hoistStatics from "hoist-non-react-statics";
import { Box, Button, ButtonGroup, Grid, Paper, Stack, /*ButtonGroup,*/ Tab, Tabs, Theme, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import DataTable, { Attribute } from "../../system/DataTable";
import PlaylistService from "./Boards.service";
import IModule, { ModuleState } from "../../system/IModule"
import { setPageTitle } from '../../App'
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import { IEntry, ISearchResult } from "../../system/SearchResult.model";
import { IBoard } from "./Boards.model";
import BoardsService from "./Boards.service";
import { CRUDButton } from "../../system/CRUDButton";
import { AuthContext } from "../../system/Base";
import { Modal } from "../../system/Modal";
/*import { CRUDForm } from "../../system/CRUDForm";
import { CRUDOperation } from "../../system/CRUDOperation";*/
import BoardChange from "./BoardChange";

import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { ENTITY_VISIBILITY_TYPE } from "../../system/CRUDTable";
import { IPermission } from "../users/Users.model";
import { ITableEntity } from "../../system/ICrudService";
/*import { ICrudActionDefinitions } from "../../system/CRUDAttribute";*/
import UserStrip from "../profile/UserStrip";
import { IKiosk } from "../kiosks/Kiosks.model";
import { ITag } from "../tags/Tags.model";
import { isImage, isVideo } from "../files/Files.model";
import { BE_ROOT } from "../../system/Communicator";
import { Field, Form, Formik, FormikProps } from "formik";
import TagSelect from "../tags/TagSelect";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SellIcon from '@mui/icons-material/Sell';
import EditIcon from '@mui/icons-material/Edit'
import SaveCloseButton from "../../system/SaveCloseButton";
import SaveIcon from '@mui/icons-material/Save';

interface IState {
	currentEntity: IBoard | null
	createShown: boolean,
    updateShown: boolean,
	deleteShown: boolean,
	tagSetShown: boolean
	tabIndex: number,
	seed: number,
	updateReadOnly: boolean,
	filterIncludeTags: ITag[],
	filterExcludeTags: ITag[],
	showFilters: boolean,
}
interface IProps extends WithTranslation { }

interface IBoardExt extends IBoard {
	actions: any // TODO
}

@IModule
class Boards extends React.Component<IProps, IState, WithTranslation> {

	dataTableRef: React.RefObject<DataTable<IBoard>>;
	boardService = BoardsService;

	constructor(props: IProps) {		
		super(props);
		this.state = {
			currentEntity: null,
			createShown: false,
			updateShown: false,
			deleteShown: false,
			updateReadOnly: false,
			tabIndex: 1,
			seed: 0,
			filterExcludeTags: [],
			filterIncludeTags: [],
			showFilters: true,
			tagSetShown: false
		};

		this.triggerRefresh = this.triggerRefresh.bind(this);
		this.showCreate = this.showCreate.bind(this);
		this.showUpdate = this.showUpdate.bind(this);
		this.filterButton = this.filterButton.bind(this);
		//this.showDelete = this.showDelete.bind(this);
		this.renderTagSettings = this.renderTagSettings.bind(this);
		this.saveTags = this.saveTags.bind(this);

		this.colorView = this.colorView.bind(this);

		this.dataTableRef = React.createRef<DataTable<IBoard>>();
	} 
	
	public static getLocale() { return "module.boards"; }
	
	componentDidMount()
	{
		setPageTitle(this.props.t( Boards.getLocale()+".module-title", { ns: Boards.getLocale() }));
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Boards.getLocale()+".module-title", {ns:Boards.getLocale()})) || "???",
			route : "/boards",
			icon : <GridViewIcon />,
			weight : 80
		};
	}
	
	public static async search(input: string) {
		const res = await BoardsService.search(input);		
		return res.map((r: IEntry<IBoard>) : ISearchResult => { return {
			title : r.entity.title,
			description : '', // there's not much else to use for description...
			icon : <GridViewIcon />,
			link : "/boards?search-result-index=" + r.index
		}});
	}
	
	public static isEnabled (auth: IUserContext) {
		if (!hasController(auth, controllerKeys.board))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.board.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}

	async triggerRefresh() {
		await this.dataTableRef.current?.refresh();
	}

    async showCreate(entity: IBoard | null) {
        this.setState(() => ({
            currentEntity: entity,
            createShown: Boolean(entity)
        }),
        async () => {
            if (!entity) await this.triggerRefresh();
        });
    }

    async showUpdate(entity: IBoard | null, readOnly: boolean = false) {
        this.setState(() => ({
            currentEntity: entity,
			updateShown: Boolean(entity),
			updateReadOnly: readOnly,
        }), 
        async () => {
            if (!entity) await this.triggerRefresh();
        });
    }

    /*async showDelete(entity: IBoard | null) {
		if (!entity) await this.triggerRefresh();

        this.setState(() => ({
            currentEntity: entity,
            deleteShown: Boolean(entity)
        }));
	}*/
	
	colorView(color: string): JSX.Element {
		return <Box sx={(theme: Theme) => ({ color: theme.palette.getContrastText(color || "#FFF"), backgroundColor: color, p: '.3rem', borderRadius: '4px'})}>
			{color}
		</Box>;
	}

	filterButton() {
		let style = { padding: "4px", textAlign: "right", float: this.state.showFilters ? null : "right" };
		return <ToggleButtonGroup exclusive value={this.state.showFilters ? "1" : "0"} sx={style} onChange={(_, v) => { this.setState({ showFilters: v === "1" }) }}>
			<ToggleButton value="1" aria-label="show filters">
				<FilterAltIcon />
			</ToggleButton>
		</ToggleButtonGroup>
	}

	async saveTags(board: { tags: ITag[] }) {
		if (this.state.currentEntity) {
			await BoardsService.setTags(this.state.currentEntity, board.tags);
			this.setState({ tagSetShown: false, seed: this.state.seed + 1 });
		}
	}

	renderTagSettings(formikProps: FormikProps<{ tags: ITag[] }>): JSX.Element | null {
		const ns = Boards.getLocale();
		return <Form className='modal-form'>
			<Field name={`tags`} labelKey='tags' namespace={ns} component={TagSelect} />

			<Button className="crud-button" variant='contained' type="submit" startIcon={<SaveIcon />}>
				{this.props.t("common.save", { ns: "common" })}
			</Button>
		</Form>
	}

	render() {
		const t = this.props.t;
		const ns = Boards.getLocale();
		const modalWidth = { xs: 'min(90vw, 360px)', md: 'auto' };
		const modalHeight = '90vh';

		const AuthorField = (p: IBoardExt, k: keyof IBoardExt, perms: IPermission[] | undefined, fullEntity: ITableEntity<IBoardExt> | undefined) => <>
			{fullEntity?.access?.record.owner &&
				<UserStrip user={fullEntity?.access?.record.owner!} />
			}
		</>

		/*const CrudActions = (b: IBoardExt, _1: keyof IBoardExt, _2: IPermission[] | undefined, _3: ITableEntity<IBoardExt> | undefined, act: ICrudActionDefinitions | undefined) => <AuthContext.Consumer>
			{auth => <ButtonGroup variant="outlined">
				<CRUDButton variant="outlined" role="update" disabled={!isAuthorized(auth, permissions.board.update) || !isAuthorized(auth, permissions.media.read)}
					onClick={() => this.showUpdate(b)} />
				<CRUDButton variant="outlined" role="delete" disabled={!isAuthorized(auth, permissions.board.delete)}
					onClick={act?.deleteAction} />
				{this.state.tabIndex < 2 &&
					<CRUDButton variant="outlined" role="share" disabled={false}
						onClick={act?.shareAction} />
				}
				{this.state.tabIndex < 2 &&
					<CRUDButton variant="outlined" role="changeowner" disabled={false}
						onClick={act?.changeOwnerAction} />
				

				}								
			</ButtonGroup>}
		</AuthContext.Consumer>;*/

		return (<>
			<Typography variant="h2">
				{ t(Boards.getLocale()+".module-title", {ns:Boards.getLocale()}) }
			</Typography>

			<Tabs value={this.state.tabIndex} onChange={(_, i) => { this.setState({ tabIndex: i, seed: this.state.seed + 1 }); }} centered sx={{
				background: 'linear-gradient(0deg, rgba(128,128,128,0.1), transparent)',
				margin: '0px -25px'
			}}>
				<Tab icon={<FolderSpecialIcon />} label={t("owntype.owned", { ns: "common" })} />
				<Tab icon={<FolderSharedIcon />} label={t("owntype.supervised", { ns: "common" })} />
				<Tab icon={<DriveFileMoveIcon />} label={t("owntype.shared", { ns: "common" })} />
			</Tabs>

			<Grid container spacing={2}>

				<Grid item xs={this.state.showFilters ? 10 : 12}>
					{this.state.tabIndex === 0 && <AuthContext.Consumer>{(auth) =>
						<CRUDButton role="create" variant="contained" disabled={!isAuthorized(auth, permissions.board.create)}
							onClick={() => this.showCreate(this.boardService.createDefaultEntity())} />
					}
					</AuthContext.Consumer>}

					{!this.state.showFilters && this.filterButton()}
					<AuthContext.Consumer>{(auth) => <DataTable ref={this.dataTableRef} service={this.boardService} entityName={t(`${ns}.entity`, { ns: ns })} entityType='boards' refreshSeed={this.state.seed}
						ownMode={this.state.tabIndex === 0 ? ENTITY_VISIBILITY_TYPE.OWNED : (this.state.tabIndex === 1 ? ENTITY_VISIBILITY_TYPE.SUPERVISED : ENTITY_VISIBILITY_TYPE.SHARED)}
						customFilters={this.state.filterIncludeTags.map(x => "include=" + x.id).concat(this.state.filterExcludeTags.map(x => "exclude=" + x.id))}
						permissions={permissions.board} create={false} customEditMethod={this.showUpdate}
						edit={isAuthorized(auth, permissions.board.update) && isAuthorized(auth, permissions.media.read)}
						delete={isAuthorized(auth, permissions.board.delete)} share={true} changeOwner={true} hideActions={false} >
						<Attribute name="id" type="number" labelKey="id" namespace={ns} hideInCreateForm hideInUpdateForm hideInTable />
						<Attribute name="title" labelKey="title" namespace={ns} />
						<Attribute labelKey="image" namespace={ns} tableValueResolver={(entity: IBoard, _: keyof IBoard) => {
							return <span style={{ display: 'flex' }}><div className='checker-board' style={{ display: 'inline-block', maxWidth: '96px', maxHeight: '48px' }}>

								{isImage(entity.image) && <img src={BE_ROOT + entity.image} alt='preview'
									style={{ maxWidth: '96px', maxHeight: '48px' }} />}

								{isVideo(entity.image) && <video controls preload='false'
									src={BE_ROOT + entity.image} style={{ maxWidth: '96px', maxHeight: '48px' }}>
								</video>}
							</div></span>
						}} />
						<Attribute name="color" labelKey="color" namespace={ns}
							tableValueResolver={(entity) => this.colorView(entity.color)} />

						<Attribute labelKey="owner" namespace="common" sortable={false} hideInCreateForm hideInUpdateForm
							tableValueResolver={AuthorField} hideInTable={this.state.tabIndex === 0} />

						<Attribute labelKey="tags" namespace={ns} sortable={false} hideInCreateForm hideInUpdateForm
							tableValueResolver={(entity: IBoard, _: keyof IBoard, perms: IPermission[] | undefined) => <div><ButtonGroup variant="outlined" sx={{ alignItems: "center", minWidth: "56px" }}>
								<Button disabled={ !isAuthorized(perms || [], permissions.board.modifyTags) } sx={{ minWidth: "56px !important" }} onClick={() => {
									this.setState({ tagSetShown: true, currentEntity: entity })
								}} variant="outlined" type="submit" size="medium"><SellIcon /></Button> <span style={{ "lineHeight": "36px", "marginLeft": "10px" }}>
									{entity.tags?.map((t: ITag) => t?.name).join(", ") ?? ""}</span>
							</ButtonGroup></div>} />

						{/*<Attribute name="actions" labelKey="actions" namespace={ns} sortable={false} tableValueResolver={CrudActions} />*/}
					</DataTable>
					}
					</AuthContext.Consumer>
				</Grid>

				{!!this.state.showFilters && <Grid item xs={2} sx={{ textAlign: "right" }}>
					{ this.filterButton() }
					<Paper sx={{ padding: "10px", textAlign: "left" }}>
						<Stack spacing={2}>
							<Typography variant="h4">{t("common.filters", { ns: 'common' })}</Typography>
							<TagSelect labelKey="include-tags" namespace="common" value={this.state.filterIncludeTags} onChange={(tags) => { this.setState({ filterIncludeTags: tags, seed: this.state.seed + 1 }) }} />
							<TagSelect labelKey="exclude-tags" namespace="common" value={this.state.filterExcludeTags} onChange={(tags) => { this.setState({ filterExcludeTags: tags, seed: this.state.seed + 1 }) }} />
						</Stack>
					</Paper>
				</Grid>}
			</Grid>

			{this.state.createShown && this.state.currentEntity && this.dataTableRef.current &&
                <Modal title={this.dataTableRef.current.getCreateFormConfig().title} isOpen={this.state.createShown}
					width={modalWidth} height={modalHeight} onClose={async () => await this.showCreate(null)}>
                    
					<BoardChange entityId={this.state.currentEntity.id!} role='create' readOnly={false} onSubmit={async (board) => {
						await this.dataTableRef.current?.getCreateFormConfig().onSubmit(board);
						await this.showCreate(null);
					}} onClose={() => { this.showCreate(null); }} />
                </Modal>}

			{this.state.updateShown && this.state.currentEntity && this.dataTableRef.current &&
				<Modal title={this.state.updateReadOnly ? this.state.currentEntity.title : (this.dataTableRef.current.getUpdateFormConfig().title + ' ' + this.state.currentEntity.title)}
					isOpen={this.state.updateShown} width={modalWidth} height={modalHeight} onClose={async () => await this.showUpdate(null)}>
                    
					<BoardChange entityId={this.state.currentEntity.id!} role='update' readOnly={this.state.updateReadOnly } onSubmit={async (board) => {
						await this.dataTableRef.current?.getUpdateFormConfig().onSubmit(board);
						await this.showUpdate(null);
					}} onClose={() => { this.showCreate(null); }} />
				</Modal>}

			{this.state.tagSetShown && this.state.currentEntity && this.dataTableRef.current &&
				<Modal title={this.state.updateReadOnly ? this.state.currentEntity.title : (this.dataTableRef.current.getUpdateFormConfig().title + ' ' + this.state.currentEntity.title)}
					isOpen={this.state.tagSetShown} onClose={() => { this.setState({ tagSetShown: false }) }}>

					<Formik initialValues={{ tags: this.state.currentEntity.tags || [] }}
						onSubmit={async (board) => {
							await this.saveTags(board);
						}} component={this.renderTagSettings} />
					
				</Modal>}

			{/* this.state.deleteShown && this.state.currentEntity && this.dataTableRef.current &&
                <Modal title={this.dataTableRef.current.getDeleteFormConfig().title + ' ' + this.state.currentEntity.title}
					isOpen={this.state.deleteShown} onClose={() => this.showDelete(null)}>
                    
					<CRUDForm {...this.dataTableRef.current?.getDeleteFormConfig()}
                        entity={this.state.currentEntity}
                        role={CRUDOperation.Delete}
						attributes={[]}
						onSubmit={async (board) => {
							await this.dataTableRef.current?.getDeleteFormConfig().onSubmit(board);
							await this.showDelete(null);
						}} />
                </Modal> */}
			</>);
    }
}

export default hoistStatics(withTranslation()(Boards), Boards)